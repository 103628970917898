<template>
  <div class="index-control__container">
    <div class="mb-xl-control">
      <v-row class="mt-10">
        <v-col class="text-left" cols="12">
          <h1 class="control__title mb-6">Control de vida</h1>
          <p class="control__subtitle mb-4">
            Tome el control de las variables que hacen de su proyecto de vida
            una realidad a su alcance.
          </p>
          <p class="control__subtitle_light">
            Elija el pilar para el que necesita asesoría
          </p>
        </v-col>
      </v-row>
    </div>

    <!-- modal temporal -->
    <!-- <v-dialog v-model="modalSend" justify="center" max-width="400">
      <v-card>
        <v-card-title
          style="justify-content: center"
          class="text-h5 request-project"
        >
          Hemos enviado su solicitud
        </v-card-title>
        <v-card-text class="request-txt-project">
          <p>Pronto le contactará su padrino para asesorarle en su solicitud</p>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <!-- <v-dialog v-model="modalVality" justify="center" max-width="400">
      <v-card>
        <v-card-title style="justify-content: center">
          <h3 style="color: #141b33">¡Tiene una solicitud pendiente!</h3>
        </v-card-title>
        <v-card-text>
          <p>Espere nuestra respuesta para asesorarle en su solicitud</p>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <v-row class="mx-0 px-0 px-md-0 c-control">
      <v-col
        class="px-3 px-sm-0 px-md-0 px-lg-0 px-xl-0"
        cols="12"
        md="12"
        xs="12"
        sm="6"
        lg="6"
        xl="6"
      >
        <!-- <p class="mb-10 text-title-xl mb-xl-control">
          Tome el control de las variables que hacen de su proyecto de vida una
          realidad a su alcance. Elija el pilar para el que necesita asesoría
        </p> -->
        <div class="mb-sm-control mb-8">
          <div class="d-flex align-center">
            <v-btn
              @click="$router.go(-1)"
              x-small
              class="mt-0"
              fab
              icon
              style="width: 10%"
            >
              <img width="15" src="../../assets/icon/navigate_before.png" />
            </v-btn>
            <p
              style="width: 90%"
              class="text-title-1 index-control__title-mobile mb-0"
            >
              <span>{{ name }},</span> acá encontrará
            </p>
            <!-- <p style="padding-top: 5%" class="text-title-1">
              <label
                style="padding-top: 5%"
                class="index-control__title-mobile txt-capitalize"
              >
                {{ name }},
              </label>
              <label
                style="padding-top: 5%"
                class="index-control__title-mobile"
              >
                acá encontrará
              </label>
            </p> -->
          </div>
          <p class="text-control-dark-sm mb-1 ml-9">Control de vida</p>
        </div>

        <Projects
          class="v-step-16 v-step-mobile-24"
          @open="modal.projectM = true"
          @info="getInfo($event)"
          :act="act"
        ></Projects>
      </v-col>
      <v-col>
        <v-card
          outlined
          style="height: 100%; border-radius: 15px; text-align: left"
          class="d-flex align-center"
        >
          <div style="width: 100%">
            <p class="control__subtitle mt-6 mb-3 pl-8 text-center">
              Estado de su solicitud
            </p>
            <v-alert
              v-show="!showControl"
              class="text-left mt-4 text-center mx-8"
              icon="mdi-information-outline"
              prominent
              text
              type="info"
            >
              <p
                class="mb-0 index-control__alert-desktop"
                style="font-size: 18px"
              >
                No se encontraron resultados, seleccione alguna de las variables
                que hacen de su proyecto de vida una realidad a su alcance.
              </p>
              <p
                class="mb-0 index-control__alert-mobile"
                style="font-size: 18px"
              >
                No se encontraron resultados, seleccione alguna de las
                variables.
              </p>
            </v-alert>
            <v-stepper
              v-show="showControl"
              v-model="step"
              alt-labels
              class="mt-4"
              elevation="0"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-stepper-header v-bind="attrs" v-on="on">
                    <v-stepper-step
                      :complete="step >= 1"
                      color="#476be4"
                      step="1"
                    >
                      Recibido
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :complete="step >= 2"
                      color="#476be4"
                      step="2"
                    >
                      En revisión
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :complete="step >= 3"
                      color="#476be4"
                      step="3"
                    >
                      Finalizado
                    </v-stepper-step>
                  </v-stepper-header>
                </template>
                <!-- <span> {{ stepComentary }} </span> -->
              </v-tooltip>
            </v-stepper>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <ModalDescription
      @close="modal.projectM = false"
      :modal="modal.projectM"
      :infoProject="info"
      @data="setReason($event)"
      :loadingM="loadingM"
    >
    </ModalDescription>

    <!-- Dialog response -->
    <!-- <v-dialog v-model="dialog" content-class="dialog">
      <v-card>
        <v-card-text class="pt-3">
          <p class="dialogMessage">
            {{ dialogMessage }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog> -->

    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>
  </div>
</template>
<script>
import ModalDescription from "./ModalDescription";
import Projects from "./Projects";
import Alert from "../../components/Alert.vue";
import Api from "@/utils/api";

export default {
  components: {
    Alert,
    ModalDescription,
    Projects,
  },
  data() {
    return {
      step: 1,
      showControl: false,
      alert: {
        txt: "",
        title: "",
        type: "",
        open: false,
        redirect: "",
      },
      loadingM: false,
      loadingAll: false,
      modalSend: false,
      modalVality: false,
      info: {},
      infoToken: {
        document: "",
      },
      modal: {
        open: false,
        projectM: false,
      },
      act: false,
      data: {
        description: "",
        lifeprojectid: "",
        date: "",
        contractorid: "",
        userid: "",
        useremail: "",
        status: "true",
        fecha_registro: "",
        full_name: "",
        ciudad: "",
        celular: "",
        centro_costos: "",
        subcentro_costos: "",
        campana: "",
      },
      dialog: false,
      dialogMessage: "",
      infoProfile: null,
      name: "",
    };
  },
  computed: {
    statusControl() {
      let statusControl = this.$store.getters.getStatusControl;
      if (statusControl) {
        if (statusControl.flag) {
          this.showControl = false;
          this.listProjects = statusControl.projects;
          this.loadingAll = false;
        } else {
          this.showControl = true;
          if (statusControl.activeRequest) {
            switch (statusControl.activeRequest.status) {
              case "Enviado":
                this.step = 1;
                break;
              case "En proceso":
                this.step = 2;
                break;
              case "Finalizado":
                this.step = 3;
                break;
              default:
                this.step = 1;
                break;
            }
          }
          this.loadingAll = false;
        }
      } else {
        this.loadingAll = true;
      }
    },
    getUser() {
      let user = this.$store.getters.getUser;
      if (user) {
        this.loadingAll = false;
        this.infoProfile = user;
      } else {
        this.loadingAll = true;
      }
    },
  },
  methods: {
    getInfo(e) {
      this.info = e;
    },
    setDataInitial(e) {
      this.data.contractorid = e.company;
      this.data.date = e.dateContract;
    },
    setReason(e) {
      this.data.description = e;
      this.data.lifeprojectid = this.info.id.toString();
      this.save();
    },
    getToken() {
      var token = localStorage.getItem("token");
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));

        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = this.toTitleCase(nameList[0]);
          } else {
            this.name = this.toTitleCase(nameList[1]);
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    toTitleCase(str) {
      return str
        .toLowerCase()
        .split(" ")
        .map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join(" ");
    },
    save() {
      this.loadingAll = true;
      this.data.userid = this.infoToken.document;
      this.data.useremail = this.infoToken.emailPersonal;
      this.data.contractorid = sessionStorage.getItem("contractor");
      this.data.date = sessionStorage.getItem("dateAdmision");
      this.data.celular = sessionStorage.getItem("phone");
      this.data.ciudad = sessionStorage.getItem("city");
      this.data.full_name = sessionStorage.getItem("fullName");
      this.data.fecha_registro = new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10);
      this.data.centro_costos = this.infoProfile.cc;
      this.data.subcentro_costos = this.infoProfile.cod_scc;
      this.data.campana = this.infoProfile.campaign;
      // this.data.pais=this.infoProfile.country;
      this.loadingM = true;
      Api.noAuth()
        .save(this.data)
        .then((resp) => resp.json())
        .then((data) => {
          this.loadingAll = false;
          this.loadingM = false;
          if (data.cod == 0) {
            this.showControl = true;
            this.alert.open = true;
            this.alert.title = "Excelente";
            this.alert.txt =
              " Pronto su padrino se comunicará contigo para hacer realidad su proyecto de vida";
            this.alert.type = "success";
            this.act = true;
            // setTimeout(() => {
            //   location.reload();
            // }, 1000);
          } else if ((data.cod = 1001)) {
            this.alert.open = true;
            this.alert.title = "Para que sepas...";
            this.alert.txt = "Ya tienes un registro activo";
            this.alert.type = "info";
          } else {
            this.alert.open = true;
            this.alert.title = "¡Oh no!";
            this.alert.txt = "Intenta mas tarde...";
            this.alert.type = "error";
          }
        })
        .catch((error) => {
          this.loadingM = false;
          this.alert.open = true;
          this.alert.title = "¡Oh no!";
          this.alert.txt = "Intenta mas tarde...";
          this.alert.type = "error";
        });
      this.modal.projectM = false;
    },
    getDataInfo() {
      this.loadingAll = true;
      this.data.userid = this.infoToken.document;
      this.data.useremail = this.infoToken.emailPersonal;

      //console.log(`datos prueba -- ${JSON.stringify(this.data)}`);
      Api.noAuth()
        .getDataInfo(this.data.userId)
        .then((resp) => resp.json())
        .then((data) => {})
        .catch((error) => {
          console.log(error);
        });

      // console.log(this.data);
      this.modal.projectM = false;
    },
  },
  mounted() {
    this.getToken();
    this.modal.open = true;
  },
  watch: {
    getUser: function (newCount, oldCount) {
      console.log("");
    },
    statusControl: function (newCount, oldCount) {
      console.log("");
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.control__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.control__subtitle {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}

.control__subtitle_light {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 35px;
  color: #353535;
}

.mb-sm-control {
  display: none;
}

.dialogMessage {
  padding-top: 35px !important;
  font-size: 30px;
  color: #466be3;
  font-family: "AsapSemiBold";
  font-weight: 700;
  text-align: center;
  word-break: break-word !important;
  line-height: 30px;
}

.request-project {
  color: #466be3;
  font-family: "AsapSemiBold" !important;
  font-weight: 700 !important;
  word-break: normal;
  text-align: center;
}

.request-txt-project {
  font-family: "ProximaNovaLight";
  color: #898989 !important;
  font-weight: 300;
}

.index-control__container {
  padding-left: 32px !important;
  padding-right: 32px !important;
}

.index-control__alert-desktop {
  display: block !important;
}

.index-control__alert-mobile {
  display: none !important;
}

/* LAPTOP 4k */

@media (max-width: 2000px) {
  .dialog {
    width: 30% !important;
  }

  .control-spacing {
    padding-left: 32px !important;
    padding-right: 32px !important;
    margin-left: 12px !important;
    margin-right: 12px !important;
  }
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .mb-sm-control {
    display: block;
    text-align: initial;
  }

  .mb-xl-control {
    display: none;
  }

  .control-spacing {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .index-control__container {
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-bottom: 70px !important;
  }

  .index-control__alert-desktop {
    display: none !important;
  }

  .index-control__alert-mobile {
    display: block !important;
  }

  .index-control__title-mobile {
    font-weight: 300;
    font-size: 25px;
    line-height: 30px;
    color: #466be3;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  .mb-xl-control {
    display: none;
  }

  .text-control-dark-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }

  .c-control {
    /* padding-top: 10px !important; */
    margin-top: 10px !important;
  }

  .global-control {
    margin-top: 0 !important;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  .mb-xl-control {
    display: none;
  }
  .mb-sm-control {
    display: block;
    text-align: initial;
  }
  .text-control-dark-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  .mb-xl-control {
    display: none;
  }
  .mb-sm-control {
    display: block;
    text-align: initial;
  }
  .text-control-dark-sm {
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    color: #626262;
    font-family: "AsapRegular";
  }
}
</style>
